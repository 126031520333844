<template lang="">
  <div>
    <!-- SECTION Card ket qua -->
    <BCard header-class="py-1">
      <template #header>
        <div class="font-weight-bolder text-uppercase">
          {{ $t('packageConfig.agencyPackageConfigsList') }}
        </div>
      </template>

      <!-- FILTER AND BUTTON -->
      <div class="d-flex flex-md-row flex-column align-items-stretch gap-1">
        <BRow class="mb-50 flex-1">
          <!-- Agency -->
          <BCol
            v-if="!isRoleF2"
            md="4"
            cols="12"
          >
            <b-form-group
              label-for="agency-code-filter"
              label-class="h6"
              :label="$t('packageConfig.columns.agency')"
              class="w-100 mb-0 mt-25 mt-lg-0"
            >
              <v-select
                id="agency-code-filter"
                v-model="agencyCodeFilter"
                class="w-100"
                style="font-size: 1rem;"
                :options="agencyOptions"
                label="agencyCode"
                clearable
                :loading="loadingAgencies"
                :placeholder="$t('packageConfig.placeholder.agency')"
                :reduce="val => val.agencyCode"
                @open="handleOpenAgency"
                @search="handleSearchAgency"
              >
                <template #selected-option="{agencyName, agencyCode}">
                  <div style="width: 100%">
                    <span class="d-block font-weight-bold text-truncate">
                      {{ agencyCode }} <small>({{ agencyName }})</small>
                    </span>
                  </div>
                </template>
                <template #option="{agencyName, agencyCode}">
                  <div style="width: 100%">
                    <span class="d-block font-weight-bold text-truncate">
                      {{ agencyCode }} <small>({{ agencyName }})</small>
                    </span>
                  </div>
                </template>
                <template #spinner="{ loading }">
                  <div
                    v-if="loading"
                    style="border-left-color: rgba(88, 151, 251, 0.71)"
                    class="vs__spinner"
                  />
                </template>
                <template #no-options>
                  <div
                    v-if="loading"
                    style="border-left-color: rgba(88, 151, 251, 0.71)"
                    class="vs__spinner"
                  />
                  <div v-else>
                    {{ $t('noOptions') }}
                  </div>
                </template>
              </v-select>
            </b-form-group>
          </BCol>
          <!-- Package config -->
          <BCol
            md="4"
            cols="12"
          >
            <b-form-group
              label-for="package-config-filter"
              label-class="h6"
              :label="$t('packageConfig.columns.packageConfig')"
              class="w-100 mb-0 mt-25 mt-lg-0"
            >
              <v-select
                id="package-config-filter"
                v-model="packageConfigIdFilter"
                class="w-100"
                style="font-size: 1rem;"
                :options="packageConfigOptions"
                label="name"
                clearable
                :loading="loadingPackageConfigs"
                :placeholder="$t('packageConfig.columns.packageConfig')"
                :reduce="val => val.id"
                @open="handleOpenPackageConfig"
                @search="handleSearchPackageConfig"
              >
                <template #selected-option="{name, price}">
                  <div style="width: 100%">
                    <span class="d-block font-weight-bolder text-truncate">
                      {{ name }} <small>({{ formatCurrency(price) }})</small>
                    </span>
                  </div>
                </template>
                <template #option="{name, price}">
                  <div style="width: 100%">
                    <span class="d-block font-weight-bolder text-truncate">
                      {{ name }} <small>({{ formatCurrency(price) }})</small>
                    </span>
                  </div>
                </template>
                <template #spinner="{ loading }">
                  <div
                    v-if="loading"
                    style="border-left-color: rgba(88, 151, 251, 0.71)"
                    class="vs__spinner"
                  />
                </template>
                <template #no-options>
                  <div
                    v-if="loading"
                    style="border-left-color: rgba(88, 151, 251, 0.71)"
                    class="vs__spinner"
                  />
                  <div v-else>
                    {{ $t('noOptions') }}
                  </div>
                </template>
              </v-select>
            </b-form-group>
          </BCol>
          <!-- Is expired -->
          <BCol
            md="4"
            cols="12"
          >
            <b-form-group
              label-for="is-expired-filter"
              label-class="h6"
              :label="$t('packageConfig.columns.isExpired')"
              class="w-100 mb-0 mt-25 mt-lg-0"
            >
              <v-select
                id="is-expired-filter"
                v-model="isExpiredFilter"
                class="w-100"
                style="font-size: 1rem;"
                :options="isExpiredOptions"
                label="label"
                clearable
                :placeholder="$t('packageConfig.columns.isExpired')"
                :reduce="val => val.value"
              >
                <template #selected-option="{label}">
                  {{ $t(`packageConfig.${label}`) }}
                </template>
                <template #option="{label}">
                  {{ $t(`packageConfig.${label}`) }}
                </template>
                <template #no-options>
                  <div
                    v-if="loading"
                    style="border-left-color: rgba(88, 151, 251, 0.71)"
                    class="vs__spinner"
                  />
                  <div v-else>
                    {{ $t('noOptions') }}
                  </div>
                </template>
              </v-select>
            </b-form-group>
          </BCol>
        </BRow>
        <div class="d-flex-center align-items-end pb-50">
          <BButton
            variant="flat-danger"
            class="p-1"
            @click="clearFilter"
          >
            Xoá bộ lọc
          </BButton>
        </div>
      </div>

      <!-- TABLE -->
      <BTable
        ref="refTable"
        style="max-height: calc(100vh - 400px); height: calc(100vh - 400px)"
        :sticky-header="true"
        class="position-relative"
        :items="resultArray"
        responsive
        :striped="true"
        :fields="tableColumnsRole"
        primary-key="id"
        show-empty
        :empty-text="$t('noMatchingResult')"
        no-border-collapse
        :busy="loading"
        :hover="true"
        :borderless="true"
      >
        <template #table-busy>
          <div class="d-flex-center text-dark my-2 gap-2">
            <b-spinner class="align-middle" />
            <strong>{{ $t('loading') }}</strong>
          </div>
        </template>

        <template
          v-for="column in tableColumnsRole"
          #[`head(${column})`]=""
        >
          <span
            :key="column"
            class="text-dark text-nowrap"
          >
            {{ $t(`packageConfig.columns.${column}`) }}
          </span>
        </template>

        <template #cell(agency)="{item}">
          <div>
            <div class="font-weight-bolder">
              {{ item.agency.agencyCode }}
            </div>
            <small class="font-italic">({{ item.agency.agencyName }})</small>
          </div>
        </template>

        <template #cell(parentAgency)="{item}">
          <div>
            <div class="font-weight-bolder">
              {{ item.agency.parentAgency.agencyCode }}
            </div>
            <small class="font-italic">({{ item.agency.parentAgency.agencyName }})</small>
          </div>
        </template>

        <template #cell(packageConfig)="row">
          <div class="d-flex-center justify-content-start gap-2">
            {{ row.item.packageConfig.name }}
            <BButton
              variant="flat-primary"
              size="sm"
              @click="handleToggleRowDetail(row, false)"
            >
              Chi tiết gói
            </BButton>
          </div>
        </template>

        <template #cell(monthsUse)="row">
          <div class="d-flex-center justify-content-start gap-2">
            {{ row.item.monthsUse }}
          </div>
        </template>

        <template #cell(issueLimit)="row">
          <div class="d-flex-center justify-content-start gap-2">
            {{ row.item.issueLimit }}
          </div>
        </template>

        <template #cell(expiredAt)="{item}">
          {{ convertISODateTime(item.expiredAt).date }}
          <div
            v-if="item.expiredAt"
            :class="getExpiredCount(item.expiredAt)[1]"
          >
            {{ getExpiredCount(item.expiredAt)[0] }}
          </div>
        </template>

        <template #cell(enable)="{item}">
          <IAmSwitch
            :checked.sync="item.enable"
            name="check-button"
            :confirm="true"
            custom-class="mr-0 mt-50 custom-control-success"
            :disabled="false"
            :re-update="false"
            @input="changeActive(item)"
          />
        </template>

        <template #cell(action)="row">
          <div class="d-flex gap-2">
            <BButton
              v-if="isRoleF2"
              variant="warning"
              size="sm"
              @click="increaseButtonHandle(row.item)"
            >
              {{ $t('packageConfig.agencyPackageConfigsIncrease') }}
            </BButton>
            <BButton
              variant="outline-info"
              size="sm"
              @click="handleToggleRowDetail(row, true)"
            >
              Chi tiết sử dụng gói
            </BButton>
            <BButton
              variant="info"
              size="sm"
              @click="handleEditDomain(row.item)"
            >
              Cài đặt domain
            </BButton>
          </div>
        </template>

        <template #row-details="{ item : { packageConfig, packagePeriod, apiKey, userApiKey } }">
          <div
            v-if="isShowDetailPeriod"
            class="d-flex gap-3"
          >
            <PackageDetail
              v-if="packagePeriod"
              :options-period="packagePeriod"
              :limit-list="packageConfig.type !== 'INVOICE' ? {
                searchLimit: packageConfig.searchLimit,
                bookingLimit: packageConfig.bookingLimit,
                issueLimit: packageConfig.issueLimit,
              } : {
                issueLimit: packageConfig.issueLimit,
              }"
            />
            <div v-else>
              <div class="text-danger">
                {{ $t('packageConfig.noPeriod') }}
              </div>
            </div>
            <ApiKeyDetail
              v-if="apiKey"
              title="API KEY"
              :api-key="apiKey"
            />
            <ApiKeyDetail
              v-if="userApiKey"
              title="USER API KEY"
              :api-key="userApiKey"
            />
          </div>
          <PackageDetail
            v-else
            :options="packageConfig.descriptions"
          />
        </template>
      </BTable>

      <!-- ANCHOR: Table Footer -->
      <b-row class=" d-flex-center justify-content-md-between">
        <b-col
          cols="12"
          md="auto"
          class="d-flex align-items-center justify-content-center mb-50 mb-md-0"
        >
          <span class="font-weight-bolder">
            {{ $t('paginationText.showing') }}
          </span>
          <v-select
            v-model="sizePerPage"
            :options="sizePerPageLgOptions"
            :clearable="false"
            :append-to-body="true"
            :calculate-position="withPopper"
            class="per-page-selector d-inline-block mx-50"
          >
            <template #option="data">
              <span>
                {{ data.label }}
              </span>
            </template>

            <template #selected-option="data">
              <span>
                {{ data.label }}
              </span>
            </template>

            <template #no-options>
              {{ $t('noOptions') }}
            </template>
          </v-select>
          <span class="font-weight-bolder">
            {{ $t('paginationText.from') }} {{ fromItem }} {{ $t('paginationText.to') }} {{ toItem }} {{
              $t('paginationText.outOf') }} {{ totalItems }}
          </span>
        </b-col>

        <b-col
          cols="12"
          md="auto"
          class="d-flex align-items-center justify-content-center"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalItems"
            :per-page="sizePerPage"
            first-number
            last-number
            class="mb-0 mt-25 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </BCard>
    <!-- !SECTION -->

    <ModalBuy
      :buy-item-props="increaseItem"
      :agency-item="agencyItem"
      :is-increase="true"
      @refetchData="fetchAgencyPackageConfigs"
    />

    <ModalDomainConfig
      :id="configItem.id"
      :domain-list="configItem.domains"
      @refetchData="fetchAgencyPackageConfigs"
    />
  </div>
</template>

<script>
import {
  BCard, BButton, BRow, BCol, BFormGroup, BTable, BSpinner, BPagination,
} from 'bootstrap-vue'
import { createPopper } from '@popperjs/core'
import { ref, computed } from '@vue/composition-api'
import moment from 'moment'

import { sizePerPageLgOptions } from '@/constants/selectOptions'
import useAgencyPackageConfig from '@/views/retail-package/agency-package-config/useAgencyPackageConfig'
import store from '@/store'

import { formatCurrency, convertISODateTime } from '@core/utils/filter'
import { removeAccentsUpperCaseFormatter } from '@core/comp-functions/forms/formatter-input'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BTable,
    BSpinner,
    BPagination,
    PackageDetail: () => import('@/views/retail-package/components/PackageDetail.vue'),
    ApiKeyDetail: () => import('@/views/retail-package/components/ApiKeyDetail.vue'),
    ModalBuy: () => import('@/views/retail-package/components/ModalBuy.vue'),
    vSelect: () => import('vue-select'),
    IAmSwitch: () => import('@/components/IAmSwitch.vue'),
    ModalDomainConfig: () => import('@/views/retail-package/components/ModalDomainConfig.vue'),
  },
  methods: {
    withPopper(dropdownList, component, { width }) {
      const dropdownMaxWidth = width || '200px'
      dropdownList.style.maxWidth = dropdownMaxWidth
      dropdownList.style.zIndex = '10000'
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'top',
        modifiers: [],
      })
      return () => popper.destroy()
    },
  },
  setup() {
    const {
      agencyCodeFilter,
      packageConfigIdFilter,
      isExpiredFilter,
      isExpiredOptions,
      resultArray,
      currentPage,
      sizePerPage,
      loading,
      totalItems,
      clearFilter,
      fromItem,
      toItem,
      fetchAgencyPackageConfigs,
      tableColumns,
      changeActive,
      refTable,
      handleOpenAgency,
      handleSearchAgency,
      agencyOptions,
      loadingAgencies,
      packageConfigOptions,
      loadingPackageConfigs,
      handleOpenPackageConfig,
      handleSearchPackageConfig,
    } = useAgencyPackageConfig()

    const isRoleF2 = computed(() => store.getters['userStore/getRoleF2'])
    fetchAgencyPackageConfigs()

    const tableColumnsRole = isRoleF2.value ? tableColumns.value.filter(item => !['parentAgency'].includes(item)) : tableColumns.value

    const increaseItem = ref(null)

    const getExpiredCount = expiredAt => {
      const countDayBeforeExpired = moment(expiredAt).startOf('days').diff(moment().startOf('days'), 'days')
      if (countDayBeforeExpired < 0) return [`Đã hết hạn ${-countDayBeforeExpired} ngày`, 'text-danger']
      if (countDayBeforeExpired <= 7) return [`Sẽ hết hạn sau ${countDayBeforeExpired} ngày`, 'text-warning']
      return [`Sẽ hết hạn sau ${countDayBeforeExpired} ngày`, 'text-muted']
    }

    function createButtonHandle() {
      increaseItem.value = null
      this.$bvModal.show('modal-create-agency-package-config')
    }

    const agencyItem = ref(null)
    function increaseButtonHandle(item) {
      increaseItem.value = item?.packageConfig
      agencyItem.value = item?.agency
      this.$bvModal.show('modal-buy-package-config')
    }

    const isShowDetailPeriod = ref(false)
    function handleToggleRowDetail(row, showPeriod = false) {
      const { detailsShowing, toggleDetails } = row
      if (!detailsShowing || (isShowDetailPeriod.value === showPeriod)) {
        toggleDetails()
      }
      isShowDetailPeriod.value = showPeriod
    }
    const configItem = ref([])
    function handleEditDomain(item) {
      configItem.value = item
      this.$bvModal.show('modal-domain-config')
    }

    return {
      loading,
      resultArray,
      tableColumnsRole,

      removeAccentsUpperCaseFormatter,
      clearFilter,
      sizePerPageLgOptions,
      currentPage,
      sizePerPage,
      totalItems,
      fromItem,
      toItem,
      formatCurrency,
      convertISODateTime,
      fetchAgencyPackageConfigs,

      changeActive,
      createButtonHandle,
      increaseButtonHandle,
      getExpiredCount,
      increaseItem,
      isRoleF2,

      agencyCodeFilter,
      agencyOptions,
      loadingAgencies,
      handleOpenAgency,
      handleSearchAgency,
      refTable,
      agencyItem,
      isExpiredOptions,
      packageConfigIdFilter,
      isExpiredFilter,
      packageConfigOptions,
      loadingPackageConfigs,
      handleOpenPackageConfig,
      handleSearchPackageConfig,
      handleToggleRowDetail,
      isShowDetailPeriod,
      handleEditDomain,
      configItem,
    }
  },
}
</script>

<style lang="">

</style>
